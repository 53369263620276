<template>
  <div>
    <section class="dashboardFilterHeader">
      <header-filters
        :filter-names="['calendar_type']"
        @onChange="onFiltersChange"
      />
    </section>

    <v-container
      fluid
      class="chargingDashboardContent"
    >
      <v-row dense>
        <!-- Card 8 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span
                class="cardTitle linkTextWrap"
                @click="goToChargePointDetail"
              >Charge Points</span>
              <div class="MapLinkCard">
                View Map
              </div>
            </div>
            <v-card-text class="cardListWrap fullWidth">
              <ul>
                <li>Public (31)</li>
                <li>Private (22)</li>
                <li>Personal (2)</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 2 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">Active Charging Sessions</span>
            </div>
            <v-card-text>
              <p class="card-value hightlightCol">
                2
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 1 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span
                class="cardTitle linkTextWrap"
                @click="goToDetail"
              >Faults & Connectivity Issues</span>
              <div class="linkIconWrap">
                <div @click="goToDetail">
                  <v-icon
                    class="mr-2"
                    small
                  >
                    mdi-eye
                  </v-icon>
                </div>
              </div>
            </div>
            <v-card-text>
              <p class="card-value">
                3
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        

        <!-- Card 3 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">Total Charging Sessions</span>
              <div class="dateFilterCard">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  offset-x
                  left
                  :nudge-right="126"
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedDate"
                      v-bind="attrs"
                      label=""
                      readonly
                      append-icon="mdi-calendar"
                      hide-details
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu = false"
                  />
                </v-menu>
              </div>
            </div>
            <v-card-text>
              <p class="card-value">
                1,590
              </p>
              <div class="card-details">
                <Icons
                  name="chart-icon"
                  class="cardIcon increase"
                />
                <span class="percentText">5% Increase</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 4 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">Total Energy</span>
              <div class="dateFilterCard">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  offset-x
                  left
                  :nudge-right="126"
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedDate"
                      v-bind="attrs"
                      label=""
                      readonly
                      append-icon="mdi-calendar"
                      hide-details
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu1 = false"
                  />
                </v-menu>
              </div>
            </div>
            <v-card-text>
              <p class="card-value">
                55,456 <span class="smallValueText">kWh</span>
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 5 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">Total Revenue</span>
            </div>
            <v-card-text>
              <p class="card-value hightlightCol">
                $24,568
              </p>
              <div class="card-details">
                <Icons
                  name="chart-icon"
                  class="cardIcon decrease"
                />
                <span class="percentText">5% Decrease</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 6 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">New Users</span>
              <div class="dateFilterCard">
                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  offset-x
                  left
                  :nudge-right="126"
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="formattedDate"
                      v-bind="attrs"
                      label=""
                      readonly
                      append-icon="mdi-calendar"
                      hide-details
                      v-on="on"
                    />
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="menu2 = false"
                  />
                </v-menu>
              </div>
            </div>
            <v-card-text>
              <p class="card-value">
                11
              </p>
              <div class="card-details">
                <Icons
                  name="chart-icon"
                  class="cardIcon increase"
                />
                <span class="percentText">5% Increase</span>
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- Card 7 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">New Charge Point</span>
            </div>
            <v-card-text>
              <p class="card-value">
                3
              </p>
            </v-card-text>
          </v-card>
        </v-col>

        

        <!-- Card 9 -->
        <v-col
          cols="12"
          md="4"
        >
          <v-card class="dashboard-card">
            <div class="cardTopBar">
              <span class="cardTitle">Locations By City</span>
              <div class="MapLinkCard">
                View Map
              </div>
            </div>
            <v-card-text class="cardListWrap">
              <ul>
                <li>NYC (9)</li>
                <li>Vancouver (5)</li>
                <li>Santa Monica (3)</li>
                <li>Singapore (22)</li>
                <li>LA (2)</li>
                <li>Dubai (14)</li>
              </ul>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";
export default {
  components: {
    HeaderFilters: () => import("@/layouts/section/MainFilters.vue"),
    Icons: () => import("@/components/base/icons.vue"),
  },
  data() {
    return {
      menu: false,
      date: new Date().toISOString().substr(0, 10), // Initialize with today's date

      options: {
        type: "week",
        mode: "stack",
      },
      // types: ["month", "week", "day", "4day"],
      types: ["week", "day"],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      // weekdays: [
      //   { text: "Sun - Sat", value: [0, 1, 2, 3, 4, 5, 6] },
      //   { text: "Mon - Sun", value: [1, 2, 3, 4, 5, 6, 0] },
      //   { text: "Mon - Fri", value: [1, 2, 3, 4, 5] },
      //   { text: "Mon, Wed, Fri", value: [1, 3, 5] },
      // ],
      value: "",
      events: [],
      //   colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange"],

      // show event
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
    };
    
    
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "csmsBookings/getLoading",
      list: "csmsBookings/getList",
    }),
    formattedDate() {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(this.date).toLocaleDateString('en-GB', options);
    },
  },
  //   watch: {
  //     "options.calendar_type": {
  //       handler: function () {
  //       },
  //     },
  //   },
  beforeDestroy() {
    const params = {
      name: "charging_dashboard",
      filters: this.options,
    };
    this.$store.commit("redirect/SET_FILTERS", params);
  },
  methods: {
    onFiltersChange(v) {
      this.options = v;
      this.fetchData();
    },
    fetchData: debounce(async function (val) {
      await this.$store.dispatch("csmsBookings/list", this.options);
      await this.getEvents(val);
      ("");
    }, 100),
    
    goToDetail() {
    this.$router.push('/operations-troubleshooting/detail');
    },
    goToChargePointDetail() {
    this.$router.push('/charging-dashboard/detail');
    }
  },
};
</script>
<style lang="sass" scoped>
$poppinReg: 'Poppins Regular', sans-serif
$poppinMed: 'Poppins Medium', sans-serif
$poppinSemi: 'Poppins SemiBold', sans-serif
$poppinBold: 'Poppins Bold', sans-serif

$primaryColor: #23BDAA

.chargingDashboardContent
  padding-left:0
  padding-right:0

.chargingDashboardContent .row--dense > [class*=col-]
  padding: 12px !important

.dashboard-card
  position: relative
  min-height: 175px
  margin: 0
  border-radius: 7px

  &::before
    content:''
    box-sizing: border-box
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 9
    z-index: 1
    background: #FFFFFF
    border: 1px solid #F3F3F3
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  &::after
    content:''
    position: absolute
    width: 100%
    height: 100%
    left: 0
    top: 3px
    background: #F9F9F9
    box-shadow: 0px 9px 4px rgba(0, 0, 0, 0.01), 0px 4px 4px rgba(0, 0, 0, 0.02), 0px 17.9868px 35.9736px rgba(190, 185, 185, 0.01)
    border-radius: 7px

  .cardTopBar
    width: 100%
    border-bottom: 1px solid #f3f3f3
    padding: 1.3rem 1.6rem 0.7rem
    margin-bottom: 2rem
    z-index: 2
    position: relative
    display: flex
    align-items: center
    flex-wrap: wrap
    min-height: 62px

  .cardTitle
    font-size: 1.1rem
    color: #000
    font-family: $poppinSemi

  .card-value 
    font-size: 2.8rem
    font-family: $poppinSemi
    z-index: 2
    position: relative

    &.hightlightCol
      color: #23BDAA

    .smallValueText
      font-size: 0.875rem
      font-family: &poppinReg

  .v-card__text
    display: flex
    flex-wrap: wrap
    padding: 1.3rem 1.6rem 0.7rem
    align-items: center

  .card-details 
    z-index: 2
    position: relative
    display: flex
    flex-direction: column
    margin-left: auto

    .cardIcon
      &.increase
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #10b981

      &.decrease 
        :deep span
          svg
            width: 1.45rem
            height: 1.45rem
            path
              fill: #ef4444

    span.percentText
      display: flex
      margin-top: 0.3rem
      font-size: 0.75rem
      font-family: $poppinMed

  .dateFilterCard
    display: flex
    margin-left: auto
    width:35%
    .v-input
      padding-top:0 !important
      margin-bottom: 0 !important
      margin-top:0 !important
      :deep .v-input__control
        .v-input__slot
          background: #F6F6F6
          border-radius: 6px
          padding: 0 0.8rem
          &:before
            display: none
          &:after
            display: none
          .v-text-field__slot
            input
              font-size: 0.875rem
              color: #22272E
          .v-input__append-inner
            .v-input__icon
              justify-content: flex-end
              i
                color: $primaryColor
                font-size: 1rem
  .MapLinkCard
    display: flex
    margin-left: auto
    color: #C1C7C6
    text-decoration: none
    font-family: $poppinReg
    font-size: 0.875rem
  .cardListWrap
    display: flex
    position: relative
    padding-top: 0
    padding-bottom: 0
    z-index: 2
    ul
      display: flex
      flex-wrap: wrap
      padding: 0
      margin-bottom: 1rem
      li
        font-size: 0.9375rem
        color: #000
        display: flex
        flex-wrap: wrap
        width: 50%
        margin-bottom: 0.4rem
    &.fullWidth
      ul
        li
          width: 100%
.dashboardFilterHeader
  .headerBarWrap
    align-items: center
    padding: 6px 12px 0

  :deep .filterBtn
    display: none !important


.drawerOpen .dashboard-card .cardTitle
  font-size: 1rem

.drawerOpen .chargingDashboardContent .row--dense > [class*=col-]
    padding: 12px 6px !important

.drawerOpen .dashboard-card .cardTopBar
  padding: 1rem 1rem 0.7rem

.drawerOpen .dashboard-card .dateFilterCard
  width: 40%

.linkIconWrap
  margin-left: auto
  display: flex
  color: #000
  cursor: pointer
  i
    color: #000

.linkTextWrap
    display: flex
    color: #000
    cursor: pointer
    text-decoration: underline
    &:hover
      text-decoration: none
</style>
